import { intersectionWith, isEqual, mergeWith, unionWith } from 'lodash-es';
import { isArray, isObject } from '@/utils/is';

/**
 * Recursively merge two objects.
 * 递归合并两个对象。
 *
 * @param source The source object to merge from. 要合并的源对象。
 * @param target The target object to merge into. 目标对象，合并后结果存放于此。
 * @param mergeArrays How to merge arrays. Default is "replace".
 *        如何合并数组。默认为replace。
 *        - "union": Union the arrays. 对数组执行并集操作。
 *        - "intersection": Intersect the arrays. 对数组执行交集操作。
 *        - "concat": Concatenate the arrays. 连接数组。
 *        - "replace": Replace the source array with the target array. 用目标数组替换源数组。
 * @returns The merged object. 合并后的对象。
 */
export function deepMerge<T extends object | null | undefined, U extends object | null | undefined>(
  source: T,
  target: U,
  mergeArrays: 'union' | 'intersection' | 'concat' | 'replace' = 'replace',
): T & U {
  if (!target) {
    return source as T & U;
  }
  if (!source) {
    return target as T & U;
  }

  return mergeWith({}, source, target, (sourceValue, targetValue) => {
    if (isArray(targetValue) && isArray(sourceValue)) {
      switch (mergeArrays) {
        case 'union':
          return unionWith(sourceValue, targetValue, isEqual);
        case 'intersection':
          return intersectionWith(sourceValue, targetValue, isEqual);
        case 'concat':
          return sourceValue.concat(targetValue);
        case 'replace':
          return targetValue;
        default:
          throw new Error(`Unknown merge array strategy: ${mergeArrays as string}`);
      }
    }

    if (isObject(targetValue) && isObject(sourceValue)) {
      return deepMerge(sourceValue, targetValue, mergeArrays);
    }

    return undefined;
  });
}

let timeout: any = null;

/**
 * 防抖原理：一定时间内，只有最后一次操作，再过wait毫秒后才执行函数
 *
 * @param {Function} func 要执行的回调函数
 * @param {Number} wait 延时的时间
 * @param {Boolean} immediate 是否立即执行
 * @return null
 */
export function debounce(func, wait = 500, immediate = false) {
  // 清除定时器
  if (timeout !== null) clearTimeout(timeout);
  // 立即执行，此类情况一般用不到
  if (immediate) {
    const callNow = !timeout;
    timeout = setTimeout(() => {
      timeout = null;
    }, wait);
    if (callNow) typeof func === 'function' && func();
  } else {
    // 设置定时器，当最后一次操作后，timeout不会再被清除，所以在延时wait毫秒后执行func回调方法
    timeout = setTimeout(() => {
      typeof func === 'function' && func();
    }, wait);
  }
}

/**
 * opt  object | string
 * to_url object | string
 * 例:
 * this.Tips('/pages/test/test'); 跳转不提示
 * this.Tips({title:'提示'},'/pages/test/test'); 提示并跳转
 * this.Tips({title:'提示'},{tab:1,url:'/pages/index/index'}); 提示并跳转值tab上
 * tab=1 一定时间后跳转至 table上
 * tab=2 一定时间后跳转至非 table上
 * tab=3 一定时间后返回上页面
 * tab=4 关闭所有页面，打开到应用内的某个页面
 * tab=5 关闭当前页面，跳转到应用内的某个页面
 */
export const tips = function (opt: any, to_url?: any) {
  if (typeof opt == 'string') {
    to_url = opt;
    opt = {};
  }

  let title = opt.title || '';
  let icon = opt.icon || 'none';
  let endtime = opt.endtime || 2000;
  let success = opt.success || null;

  if (title) {
    uni.showToast({
      title: title,
      icon: icon,
      duration: endtime,
      success,
    });
  }
  if (to_url != undefined) {
    if (typeof to_url == 'object') {
      let tab = to_url.tab || 1,
        url = to_url.url || '';
      switch (tab) {
        case 1:
          //一定时间后跳转至 table
          setTimeout(function () {
            uni.switchTab({
              url: url,
            });
          }, endtime);
          break;
        case 2:
          //跳转至非table页面
          setTimeout(function () {
            uni.navigateTo({
              url: url,
            });
          }, endtime);
          break;
        case 3:
          //返回上页面
          setTimeout(function () {
            // #ifndef H5
            uni.navigateBack({
              delta: parseInt(url),
            });
            // #endif
            // #ifdef H5
            history.back();
            // #endif
          }, endtime);
          break;
        case 4:
          //关闭所有页面，打开到应用内的某个页面
          setTimeout(function () {
            uni.reLaunch({
              url: url,
            });
          }, endtime);
          break;
        case 5:
          //关闭当前页面，跳转到应用内的某个页面
          setTimeout(function () {
            uni.redirectTo({
              url: url,
            });
          }, endtime);
          break;
      }
    } else if (typeof to_url == 'function') {
      setTimeout(function () {
        to_url && to_url();
      }, endtime);
    } else {
      //没有提示时跳转不延迟
      setTimeout(
        function () {
          uni.navigateTo({
            url: to_url,
          });
        },
        title ? endtime : 0,
      );
    }
  }
};

/**
 * 判断当前是否为微信浏览器环境
 * @return {Boolean}
 */
export const isWeixinBrowser = () => {
  return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1;
};

/**
 * 判断当前是否为支付宝浏览器环境
 * @return {Boolean}
 */
export const isAlipayBrowser = () => {
  return navigator.userAgent.toLowerCase().indexOf('alipay') !== -1;
};

/**
 * 获取支付类型
 */
export const getTradeType = () => {
  if (isWeixinBrowser()) {
    return 'T_JSAPI';
  } else if (isAlipayBrowser()) {
    return 'A_JSAPI';
  } else {
    return '';
  }
};

/**
 * 微信浏览器jsapi支付
 * @param config
 */
export const wechatJsApiPay = (config: Recordable) => {
  return new Promise((resolve, reject) => {
    if (!isWeixinBrowser()) {
      reject('请在微信环境中使用');
    }

    const callback = () => {
      window.WeixinJSBridge.invoke('getBrandWCPayRequest', config, function (res: Recordable) {
        let is_success = true;
        let message = '支付成功';

        if (res.err_msg == 'get_brand_wcpay_request:ok') {
          is_success = true;
          message = '支付成功';
        } else if (res.err_msg == 'get_brand_wcpay_request:cancel') {
          is_success = false;
          message = '用户取消支付';
        } else {
          is_success = false;
          message = '支付失败';
        }

        tips({ title: message });
        resolve(is_success);
      });
    };

    if (window.WeixinJSBridge) {
      callback && callback();
    } else {
      document.addEventListener('WeixinJSBridgeReady', callback, false);
    }
  });
};

/**
 * 支付宝浏览器jsapi支付
 * @param config 配置参数
 * @returns
 */
export const aliJsApiPay = (config: Recordable) => {
  return new Promise((resolve, reject) => {
    if (!isAlipayBrowser()) {
      reject('请在支付宝App中使用');
    }

    const callback = () => {
      window.AlipayJSBridge.call('tradePay', config, function (res: Recordable) {
        let is_success = true;
        let message = '支付成功';

        if (res.resultCode == '9000') {
          is_success = true;
          message = '支付成功';
        } else if (res.resultCode == '6001') {
          is_success = false;
          message = '用户取消支付';
        } else {
          is_success = false;
          message = '支付失败';
        }

        tips({ title: message });
        resolve(is_success);
      });
    };

    if (window.AlipayJSBridge) {
      callback && callback();
    } else {
      document.addEventListener('AlipayJSBridgeReady', callback, false);
    }
  });
};

/**
 * 关闭当前浏览器页面
 */
export const closeBrowser = () => {
  try {
    if (isWeixinBrowser()) {
      window.WeixinJSBridge.invoke('closeWindow');
    } else if (isAlipayBrowser()) {
      window.AlipayJSBridge.call('closeWebview');
    } else {
      window.close();
    }
  } catch (error) {
    console.error('Failed to close browser:', error);
  }
};
