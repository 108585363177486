<script setup lang="ts">
  import storage from '@/utils/storage';
  import { isAlipayBrowser, isWeixinBrowser } from '@/utils';
  import { getAliOpenId, getWxOpenId } from '@/api';
  import { useUserStore } from '@/store';

  const { proxy } = getCurrentInstance();
  const { $isResolve } = proxy;

  function toAuth(path, query) {
    let app_id = '';

    const redirect_uri = encodeURIComponent(window.location.href);
    if (isWeixinBrowser()) {
      app_id = import.meta.env.VITE_WEIXIN_APP_ID;

      // 判断是否开发环境，开发环境这里走内网穿透方便授权调试
      if (import.meta.env.MODE === 'development') {
        // 将 query 转为字符串
        const queryString = Object.keys(query)
          .map((key) => `${key}=${query[key]}`)
          .join('&');

        const redirect_uri = encodeURIComponent(`https://payment.ipx.nxun.cn/${path}?${queryString}`);
        window.location.href = `https://za9ue.api.jxhhyun.com/wechat/oauth?appid=${app_id}&scope=snsapi_base&redirect_uri=${redirect_uri}`;
      } else {
        window.location.href = `https://za9ue.api.jxhhyun.com/wechat/oauth?appid=${app_id}&scope=snsapi_base&redirect_uri=${redirect_uri}&from=wechat`;
        // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${app_id}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
      }
    } else {
      // 支付宝只能配置一个授权域名
      app_id = import.meta.env.VITE_ALIPAY_APP_ID;
      window.location.href = `https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=${app_id}&scope=auth_base&redirect_uri=${redirect_uri}&state=init`;
    }
  }

  function toGetOpenId(data: Recordable) {
    return new Promise(async (resolve) => {
      const getOpenIdMethod = isWeixinBrowser() ? getWxOpenId : getAliOpenId;
      const { openid }: Recordable = await getOpenIdMethod(data);

      // 缓存 openId
      const userStore = useUserStore();
      userStore.setUserInfo({ openId: openid });

      resolve(true);
    });
  }

  onLaunch(async ({ path, query }) => {
    console.log('App Launch');

    // 获取进入时的org参数信息
    storage.set('open_query', query);

    const userStore = useUserStore();
    if (!isWeixinBrowser() && !isAlipayBrowser()) {
      uni.reLaunch({ url: '/pages/error/index?clear=1?msg=暂不支持该浏览器' });
    }

    if (!userStore.isLogined) {
      const code = isWeixinBrowser() ? query?.code : query?.auth_code;
      if (!code) {
        toAuth(path, query);
      } else {
        await toGetOpenId({
          code: code,
        });
      }
    }

    $isResolve();
  });

  onShow(() => {
    console.log('App Show');
  });

  onHide(() => {
    console.log('App Hide');
  });
</script>

<style lang="scss">
  @import '@tuniao/tn-style/dist/uniapp/index.css';

  .alipay-color {
    color: var(--tn-color-primary-light-3);
  }

  .bg-alipay-color {
    background: var(--tn-color-primary-light-3);
  }
</style>
